import React from 'react'
import DesignSystem from '../../components/DesignSystem'

import Islands from './index'

const Maui = () => {
  return (
    <Islands activeIsland="maui">
      <DesignSystem.H1>Maui</DesignSystem.H1>
      <DesignSystem.P>blurb</DesignSystem.P>
    </Islands>
  )
}

export default Maui
